<template>
  <section class="pageTopBanner">
    <img
      class="pgTopBnr__bg"
      src="@/assets/common/topBanner_bg.png"
    >
    <div
      v-if="$screen.breakpoint !== 'mobile'"
      class="pgTopBnr__title"
    >
      <span class="flex items-center">
        <p class="inline-block">{{ bannerData.title }}</p>
        <i class="icon-cook3 text-5xl pl-3" />
      </span>
    </div>
    <div
      v-else
      class="pgTopBnr__title"
    >
      <span class="flex w-[340px] items-center">
        <p class="inline-block">{{ bannerData.title }}<i class="icon-cook3 text-5xl pl-3" /></p>
      </span>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PageTopBanner',
  props: ['data'],
  data() {
    return {
      bannerData: this.data
    };
  },
};
</script>

<style lang="scss" scoped>
.pageTopBanner{
  position: relative;
  height: 400px;
}
.pgTopBnr{
  &__bg{
    position: absolute;
    z-index: 0;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  &__title{
    height: 100%;
    font-family: $fontfamilyClash;
    font-weight: 600;
    font-size: clamp(70px, 6.7vw, 80px);
    line-height: 1.14;
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
    z-index: 1;
    color: #FFF;
    position: relative;
    span{
      position: absolute;
      bottom: 56px;
    }
    :is(.tablet) &{
      font-size: 56px;
      width: 100%;
      padding: 0px 32px 0px 32px;
      word-break: keep-all;
      span{
        position: absolute;
        bottom: 40px;
      }
    }
    :is(.mobile) &{
      font-size: 36px;
      line-height: 0.9;
      width: 100%;
      padding: 0px 16px 0px 16px;
      word-break: keep-all;
      span{
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
:is(.mobile){
  .pageTopBanner{
    height: rem(360);
  }
  .icon-cook3{
    font-size: 24px;
  }
}
:is(.tablet){
  .pageTopBanner{
    height: rem(640);
  }
  .icon-cook3{
    font-size: 36px;
  }
}
</style>
